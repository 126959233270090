<template>
  <el-dialog :title="changeCountParams.status ? '停用账号': '启用账号'" :visible.sync="stopOrEnableUserVisible" :close-on-click-modal="false" width="500px" append-to-body :before-close="handleClose">
    <div class="info" v-if="!changeCountParams.status">
      您确定要启用该账号吗？
    </div>
    <div class="info" v-else>
      停用后，该单位下的所有账号均不可使用，确定停用吗？
    </div>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="changeAccountStatusPost">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { enableDisableUnit } from "@/api/unitManage"
  export default {
    name: "stopOrEnableUserCount",
    props: {
      stopOrEnableUserVisible: {
        type: Boolean,
        default: false
      },
      changeCountParams: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:stopOrEnableUserVisible', false)
      },
      //  启用、禁用账号
      async changeAccountStatusPost() {
        const res = await enableDisableUnit(this.changeCountParams)
        if(res.state === 0) {
          this.$message.success(res.data)
          this.$emit('update:stopOrEnableUserVisible', false)
          //请求成功，刷新页面
          this.$emit('reloadPage')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
</style>
